<template>
  <div class="solution">
    <div class="top">
      <img src="@/assets/images/solution_top_text.png" alt="" />
    </div>
    <div class="middle">
      <div class="container">
        <a-row :gutter="50">
          <a-col :span="12">
            <div class="left">
              <img src="@/assets/images/solution_image.png" alt="" />
            </div>
          </a-col>
          <a-col :span="12">
            <div class="right">
              <div class="title">直击农业现状痛点</div>
              <ul>
                <li>已经完成近20万亩土地托管，实现农业生产统一经营模式</li>
                <li>开发金融产品，解决贷款难、利息高等问题</li>
                <li>开发收入保险，保障新型经营主体种粮积极性</li>
                <li>科学使用农资和农机，降低生产成本，提高生产效率</li>
                <li>平台利用遥感、大数据等手段实现运营闭环管理</li>
                <li>壮大集体经济，农户实现增收</li>
              </ul>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="bottom">
      <div class="container">
        <div class="big-title">政策利好</div>
        <div class="list">
          <div class="item">
            <div class="date">
              <div class="month">02</div>
              <div class="year">2021</div>
            </div>
            <div class="title">
              <div class="subtitle">
                农业农村部：《关于统筹利用撂荒地促进农业生产发展的指导意见》
              </div>
            </div>
          </div>
          <div class="item">
            <div class="date">
              <div class="month">09</div>
              <div class="year">2020</div>
            </div>
            <div class="title">
              <div class="subtitle">
                农业农村部农村合作经济指导司：《关于组织开展中国农业社会化服务平台试点工作的通知》
              </div>
            </div>
          </div>
          <div class="item">
            <div class="date">
              <div class="month">02</div>
              <div class="year">2019</div>
            </div>
            <div class="title">
              <div class="subtitle">
                中央一号文件提出发展乡村新型服务业：落实扶持小农户和现代农业发展有机衔接的政策，完善“农户+合作社”
                “农户+公司”利益联结机制。加快培育各类社会化服务组织，为一家一户提供全程社会化服务。
              </div>
            </div>
          </div>
          <div class="item">
            <div class="date">
              <div class="month">07</div>
              <div class="year">2018</div>
            </div>
            <div class="title">
              <div class="subtitle">
                中共中央、国务院印发：《乡村振兴战略规划（2018-2022）》健全农业社会化服务体系，大力培育新型服务主体，加快
                发展“一站式”农业生产性服务业。
              </div>
            </div>
          </div>
          <div class="item">
            <div class="date">
              <div class="month">02</div>
              <div class="year">2017</div>
            </div>
            <div class="title">
              <div class="subtitle">
                中央一号文件继续关注“土地托管”：《关于深入推进农业供给侧结构性改革
                加快培育农业农村发展新动能的若干意见》
              </div>
            </div>
          </div>
          <div class="item">
            <div class="date">
              <div class="month">01</div>
              <div class="year">2016</div>
            </div>
            <div class="title">
              <div class="subtitle">
                中央一号文件正式提出“土地托管”《关于落实发展新理念加快农业现代化实现全面小康目标的若干意见》
              </div>
            </div>
          </div>
          <div class="item">
            <div class="date">
              <div class="month">02</div>
              <div class="year">2015</div>
            </div>
            <div class="title">
              <div class="subtitle">
                中央一号文件强化农业社会化服务：《关于加大改革创新力度加快农业现代化建设的若干意见》
              </div>
            </div>
          </div>
          <div class="item">
            <div class="date">
              <div class="month">01</div>
              <div class="year">2014</div>
            </div>
            <div class="title">
              <div class="subtitle">
                中央一号文件首次提出土地“托管式”服务：《关于全面深化农村改革加快推进农业现代化的若干意见》
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {};
  },
  components: {},
};
</script>

<style scoped lang="scss">
.solution {
  .top {
    text-align: center;
    background: url("~@/assets/images/solution_top.jpg") no-repeat center bottom;
    background-size: cover;
    padding: 258px 0;
  }
}
.middle {
  background: #f6f0e4;
  padding: 50px 0;
  .left {
    img {
      width: 100%;
    }
  }
  .title {
    background: url("~@/assets/images/solution_title.png") no-repeat left center;
    background-size: cover;
    font-size: 36px;
    font-weight: bolder;
    width: 376px;
    text-align: center;
    color: white;
    line-height: 72px;
    height: 96px;
    margin-bottom: 20px;
  }
  .right {
    ul {
      li {
        list-style-type: none;
        background: url("~@/assets/images/solution_right.png") no-repeat left
          center;
        font-size: 20px;
        text-indent: 40px;
        font-weight: bolder;
        color: #886b41;
        line-height: 2.5em;
      }
    }
  }
}
.bottom {
  overflow: hidden;
  background: url("~@/assets/images/solution_bottom.png") no-repeat center
    bottom / cover;
  padding-bottom: 50px;
  .big-title {
    width: 709px;
    background: url("~@/assets/images/solution_bottom_title.png") no-repeat
      center center;
    font-size: 35px;
    color: #008840;
    font-weight: bold;
    text-align: center;
    margin: 30px auto;
  }
  .list {
    .item {
      overflow: hidden;
      margin: 15px 0;
      padding: 10px 0;
      display: flex;
    }
    .title {
      font-size: 18px;
      color: #484848;
      padding-left: 1em;
      width: calc(100% - 60px);
      vertical-align: middle;
      position: relative;
      line-height: 60px;
      .subtitle {
        display: inline-block;
        line-height: 30px;
        vertical-align: middle;
      }
    }
    .date {
      width: 60px;
      height: 60px;
      background: #008840;
      text-align: center;
      color: white;
      .month {
        font-size: 22px;
        font-weight: bolder;
        line-height: 1.7em;
      }
      .year {
        font-size: 14px;
        line-height: 1em;
      }
    }
  }
}
</style>
